import { View, Text } from "react-native";
import React, { useContext } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";

const MiniBodyText = ({ children, style }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <Text style={[styles.textStyle, style]} accessibilityRole="paragraph">
        {children}
      </Text>
    </View>
  );
};

export default MiniBodyText;
