import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      height: scale(20),
      alignSelf: "center",
      marginVertical: scale(4),
    },
    dot: {
      height: scale(5),
      borderRadius: scale(2.5),
      backgroundColor: COLORS.primary,
      marginHorizontal: scale(5),
    },
  });
