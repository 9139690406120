import React, {
  useContext,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import { ScrollView, Button, View } from "react-native";

import { Context } from "../createDataContext";
import { myStyles } from "./myStyles";
import NavBar from "../components/NavBar";
import HeroSection from "../screens/HeroSection";
import CoreSkills from "../screens/CoreSkills";
import Projects from "../screens/Projects";
import Contact from "../screens/Contact";
import Footer from "../screens/Footer";

const Main = (props) => {
  const [aboutCords, setAboutCords] = useState(0);
  const [skillsCords, setSkillsCords] = useState(0);
  const [projectsCords, setProjectsCords] = useState(0);
  const [contactCords, setContactCords] = useState(0);
  const { scale, width, height, updateNaviFunction, updateNaviData } =
    useContext(Context);
  const scrollRef = useRef(null);

  useEffect(() => {
    updateNaviFunction(scrollTo);
    updateNaviData({ aboutCords, skillsCords, projectsCords, contactCords });
  }, [, aboutCords, skillsCords, projectsCords, contactCords]);

  const scrollTo = useCallback(
    (num) => {
      scrollRef.current.scrollTo({
        x: 0,
        y: num - 50,
      });
    },
    [width, height]
  );

  const styles = myStyles(scale, width, height);
  return (
    <ScrollView
      stickyHeaderIndices={[0]}
      ref={scrollRef}
      scrollToOverflowEnabled={true}
      style={styles.container}
    >
      <NavBar />

      <View
        onLayout={useCallback(
          (event) => {
            const layout = event.nativeEvent.layout;
            setAboutCords(layout.y);
          },
          [width, height]
        )}
      >
        <HeroSection />
      </View>
      <View
        onLayout={useCallback(
          (event) => {
            const layout = event.nativeEvent.layout;
            setSkillsCords(layout.y);
          },
          [width, height]
        )}
      >
        <CoreSkills />
      </View>
      <View
        onLayout={useCallback(
          (event) => {
            const layout = event.nativeEvent.layout;
            setProjectsCords(layout.y);
          },
          [width, height]
        )}
      >
        <Projects />
      </View>
      <View
        onLayout={useCallback(
          (event) => {
            const layout = event.nativeEvent.layout;
            setContactCords(layout.y);
          },
          [width, height]
        )}
      >
        <Contact />
      </View>
      <Footer />
    </ScrollView>
  );
};

export default Main;
