import React, { useContext } from "react";
import { Text, View, Image, useWindowDimensions } from "react-native";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";

const AppDemo = ({ image }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <Image
        source={{ uri: image }}
        style={styles.image}
        resizeMode="contain"
      />
    </View>
  );
};

export default AppDemo;
