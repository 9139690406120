import { View, Text } from "react-native";
import React, { useContext } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";

const SectionHeader = ({ children, style, ...rest }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={[styles.container]}>
      <Text style={[styles.textStyle, style]} {...rest}>
        {children}
      </Text>
    </View>
  );
};

export default SectionHeader;
