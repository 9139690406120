import { StyleSheet } from "react-native";

export const myStyles = (
  scale,
  width,
  height,
  hoverInColor,
  hoverOutColor,
  fontSize
) =>
  StyleSheet.create({
    textStyle: {
      fontFamily: "poppins-regular",
      fontSize: scale(fontSize),
    },
    onHoverInText: {
      color: hoverInColor,
    },
    onHoverOutText: {
      color: hoverOutColor,
    },
  });
