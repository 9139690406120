import {
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React, { useContext, useCallback } from "react";
import { FontAwesome } from "@expo/vector-icons";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import { COLORS } from "../../constants/colors";

import NavBarText from "../NavBarText";

const CustomDrawer = ({ navigation }) => {
  const { scale, width, height, naviData, naviFunction } = useContext(Context);

  const styles = myStyles(scale, width, height);
  const handleToggleDrawer = useCallback(() => {
    navigation.toggleDrawer();
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.closeIcon}>
        <TouchableOpacity onPress={handleToggleDrawer}>
          <FontAwesome name="times" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.menuContainer}>
        <NavBarText
          hoverInColor={COLORS.secondary}
          hoverOutColor={COLORS.additional}
          fontSize={9}
          onPress={() => {
            handleToggleDrawer();
            naviFunction(naviData.aboutCords);
          }}
        >
          About
        </NavBarText>
        <NavBarText
          hoverInColor={COLORS.secondary}
          hoverOutColor={COLORS.additional}
          fontSize={9}
          onPress={() => {
            handleToggleDrawer();
            naviFunction(naviData.skillsCords);
          }}
        >
          Core Skills
        </NavBarText>
        <NavBarText
          hoverInColor={COLORS.secondary}
          hoverOutColor={COLORS.additional}
          fontSize={9}
          onPress={() => {
            handleToggleDrawer();
            naviFunction(naviData.projectsCords);
          }}
        >
          Projects
        </NavBarText>
        <NavBarText
          hoverInColor={COLORS.secondary}
          hoverOutColor={COLORS.additional}
          fontSize={9}
          onPress={() => {
            handleToggleDrawer();
            naviFunction(naviData.contactCords);
          }}
        >
          Contact
        </NavBarText>
      </View>
    </View>
  );
};

export default CustomDrawer;
