import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (
  scale,
  widthProp,
  colorIn,
  colorOut,
  fontSize,
  width
) => {
  return StyleSheet.create({
    container: {
      marginVertical: scale(6),
      width: width < 650 ? scale(widthProp + 20) : scale(widthProp),
    },

    textStyle: {
      fontSize: width < 650 ? scale(fontSize + 4) : scale(fontSize),
      fontFamily: "poppins-regular",
    },

    onHoverInContainer: {
      borderColor: colorIn,
    },

    onHoverInText: {
      color: colorIn,
    },
    onHoverOutText: {
      color: colorOut,
    },
    onHoverOutContainer: {
      borderColor: colorOut,
    },
    buttonContainer: {
      borderWidth: width < 650 ? scale(1) : scale(0.5),
      justifyContent: "center",
      alignItems: "center",
      width: width < 650 ? scale(widthProp + 20) : scale(widthProp),
      borderRadius: scale(widthProp),
      padding: width < 650 ? scale(widthProp / 15) : scale(widthProp / 20),
      borderColor: COLORS.primary,
    },
  });
};
