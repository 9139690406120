import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    textStyle: {
      fontFamily: "poppins-bold",
      fontSize: width < 650 ? scale(18) : scale(12),
      color: COLORS.primary,
      textAlign: width < 650 ? "center" : "left",
    },
    container: {
      marginBottom: scale(2),
    },
  });
