import { View, Text, FlatList } from "react-native";
import React, { useContext, useLayoutEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import SkillCard from "../../components/SkillCard";
import InnerContainer from "../../components/InnerContainer";

const skills = [
  {
    id: "1",
    title: "Design",
    content:
      "High-Fidelity User Interface and Interactive Prototype using Figma.",
    icon: require("../../../assets/icons/rook.png"),
  },
  {
    id: "2",
    title: "Develop",
    content: "Cross-Platform App with a single codebase using React Native.",
    icon: require("../../../assets/icons/bishop.png"),
  },
  {
    id: "3",
    title: "Test",
    content: "Automated End-to-End Testing using Detox and Cucumber.",
    icon: require("../../../assets/icons/queen.png"),
  },
  {
    id: "4",
    title: "CI/CD",
    content:
      "Continous Integration and Delivery Pipline using App Center and GitHub.",
    icon: require("../../../assets/icons/knight.png"),
  },
];

const CoreSkills = () => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);
  const [isSingle, setIsSingle] = useState(false);
  let numColumns = 2;

  useLayoutEffect(() => {
    if (width < 650) {
      setIsSingle(false);
    }
    return () => {
      setIsSingle(false);
    };
  }, [width, height]);

  const singleColumn = (
    <>
      <View>
        <SkillCard
          skillTitle={skills[0].title}
          skillDescription={skills[0].content}
          icon={skills[0].icon}
        />
        <SkillCard
          skillTitle={skills[1].title}
          skillDescription={skills[1].content}
          icon={skills[1].icon}
        />
        <SkillCard
          skillTitle={skills[2].title}
          skillDescription={skills[2].content}
          icon={skills[2].icon}
        />
        <SkillCard
          skillTitle={skills[3].title}
          skillDescription={skills[3].content}
          icon={skills[3].icon}
        />
      </View>
    </>
  );
  const doubleColumn = (
    <>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "50%" }}>
          <SkillCard
            skillTitle={skills[0].title}
            skillDescription={skills[0].content}
            icon={skills[0].icon}
          />
          <SkillCard
            skillTitle={skills[1].title}
            skillDescription={skills[1].content}
            icon={skills[1].icon}
          />
        </View>
        <View style={{ width: "50%" }}>
          <SkillCard
            skillTitle={skills[2].title}
            skillDescription={skills[2].content}
            icon={skills[2].icon}
          />
          <SkillCard
            skillTitle={skills[3].title}
            skillDescription={skills[3].content}
            icon={skills[3].icon}
          />
        </View>
      </View>
    </>
  );

  return (
    <View style={styles.container}>
      <InnerContainer>
        <SectionHeader accessibilityRole="heading" accessibilityLevel={2}>
          Core Skills
        </SectionHeader>
        {width > 650 ? doubleColumn : singleColumn}
      </InnerContainer>
    </View>
  );
};

export default CoreSkills;
