import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    textStyle: {
      color: COLORS.primary,
      fontFamily: "poppins-light",
      letterSpacing: scale(0.5),
      fontSize: width < 650 ? scale(8) : scale(4),
      textTransform: "uppercase",
    },
    container: {
      // marginBottom: scale(1),
    },
  });
