import * as Font from "expo-font";

export const fetchFonts = () => {
  return Font.loadAsync({
    // "Poppins-Black": require("../../assets/fonts/Poppins-Black.ttf"),
    // "Poppins-BlackItalic": require("../../assets/fonts/Poppins-BlackItalic.ttf"),
    "Poppins-Bold": require("../../assets/fonts/Poppins-Bold.ttf"),
    // "Poppins-BoldItalic": require("../../assets/fonts/Poppins-BoldItalic.ttf"),
    // "Poppins-Italic": require("../../assets/fonts/Poppins-Italic.ttf"),
    "Poppins-Light": require("../../assets/fonts/Poppins-Light.ttf"),
    "Poppins-LightItalic": require("../../assets/fonts/Poppins-LightItalic.ttf"),
    "Poppins-Regular": require("../../assets/fonts/Poppins-Regular.ttf"),
  });
};
