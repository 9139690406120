import { View, Text } from "react-native";
import React, { useContext } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import InnerContainer from "../../components/InnerContainer";
import SectionHeader from "../../components/SectionHeader";
import MapCard from "../../components/MapCard";
import ContactForm from "../../components/ContactForm";
import BodyText from "../../components/BodyText";

const Contact = ({ children, style }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);

  let ContactLayout = (
    <>
      <ContactForm />
    </>
  );
  if (width > 650) {
    ContactLayout = (
      <>
        <View style={styles.contactContainer}>
          <MapCard />
          <ContactForm />
        </View>
      </>
    );
  }

  return (
    <View style={styles.container}>
      <InnerContainer>
        <SectionHeader accessibilityRole="heading" accessibilityLevel={2}>
          Contact
        </SectionHeader>
        <BodyText>
          I am open to new opportunities or collaboration. Feel free to drop in
          a message, I will try my best to get back to you as soon as possible.
        </BodyText>
        {ContactLayout}
      </InnerContainer>
    </View>
  );
};

export default Contact;
