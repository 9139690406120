import React, { useContext } from "react";
import { View } from "react-native";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";

const InnerContainer = ({ children }) => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);
  return <View style={styles.container}>{children}</View>;
};

export default InnerContainer;
