import { View, TextInput } from "react-native";
import React, { useContext, useState } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import { COLORS } from "../../constants/colors";

const Input = ({
  Icon,
  IconName,
  placeholderText,
  multi = false,
  lines = 0,
  value,
  onChangeValue,
  onEndEditing = null,
}) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <Icon name={IconName} style={styles.icon} />
      </View>
      <View style={styles.textinputContainer}>
        <TextInput
          value={value}
          placeholder={placeholderText}
          placeholderTextColor={COLORS.additional}
          onChangeText={onChangeValue}
          style={[styles.textInput, { outline: "none" }]}
          multiline={multi}
          numberOfLines={lines}
          // editable={false}
          // selectTextOnFocus={false}
          onBlur={onEndEditing}
        />
      </View>
    </View>
  );
};

export default Input;
