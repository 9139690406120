import { View, Text, FlatList } from "react-native";
import React, { useContext, useLayoutEffect, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import { COLORS } from "../../constants/colors";
import BodyText from "../BodyText";

const FeaturesList = ({ item }) => {
  const [data, setData] = useState(true);
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);

  useLayoutEffect(() => {
    setData(!data);
    return () => {};
  }, [item]);

  const ListItem = ({ title }) => {
    return (
      <View style={styles.featureContainer}>
        <View>
          <FontAwesome name="circle-o" size={scale(5)} color={COLORS.primary} />
        </View>
        <View style={{ marginLeft: scale(2) }}>
          <Text style={styles.text}>{title}</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {item.map((item) => (
        <ListItem title={item} key={item} />
      ))}
      {/* <FlatList
        data={item}
        keyExtractor={(item) => item}
        renderItem={({ item }) => }
        extraData={data}
      /> */}
    </View>
  );
};

export default FeaturesList;
