import React, {
  useContext,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  Pressable,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

import { AntDesign } from "@expo/vector-icons";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";
import NavBarText from "../NavBarText";
import { COLORS } from "../../constants/colors";

const NavBar = ({ scrollTo }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { scale, width, height, naviData, naviFunction } = useContext(Context);
  const styles = myStyles(scale, width, height);
  const navigation = useNavigation();

  useLayoutEffect(() => {
    navigation.closeDrawer();
    if (width < 650) {
      setIsSmallScreen(true);
    }
    return () => {
      setIsSmallScreen(false);
    };
  }, [width, height, navigation]);

  const handleToggleDrawer = useCallback(() => {
    navigation.toggleDrawer();
  }, [navigation]);

  const NavBarItems = (
    <View style={styles.navTextContainer}>
      <NavBarText
        hoverInColor={COLORS.additional}
        hoverOutColor={COLORS.primary}
        fontSize={5}
        onPress={() => naviFunction(naviData.aboutCords)}
      >
        About
      </NavBarText>

      <NavBarText
        hoverInColor={COLORS.additional}
        hoverOutColor={COLORS.primary}
        fontSize={5}
        onPress={() => naviFunction(naviData.skillsCords)}
      >
        Core Skills
      </NavBarText>

      <NavBarText
        hoverInColor={COLORS.additional}
        hoverOutColor={COLORS.primary}
        fontSize={5}
        onPress={() => naviFunction(naviData.projectsCords)}
      >
        Projects
      </NavBarText>

      <NavBarText
        hoverInColor={COLORS.additional}
        hoverOutColor={COLORS.primary}
        fontSize={5}
        onPress={() => naviFunction(naviData.contactCords)}
      >
        Contact
      </NavBarText>
    </View>
  );
  const MenuBar = (
    <Pressable onPress={handleToggleDrawer}>
      <AntDesign name="menuunfold" size={scale(15)} color={COLORS.primary} />
    </Pressable>
  );

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.logoContainer}>
          <Image
            source={require("../../../assets/images/logo.png")}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        {isSmallScreen ? MenuBar : NavBarItems}
      </View>
    </View>
  );
};

export default NavBar;
