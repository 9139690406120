import React, { useContext } from "react";
import { Text, View, Image, useWindowDimensions, FlatList } from "react-native";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import BodyText from "../BodyText";
import MiniSubHeader from "../MiniSubHeader";
import FeaturesList from "../../components/FeaturesList";

const AppDescription = ({ item }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text
          style={styles.titleText}
          accessibilityRole="heading"
          accessibilityLevel={3}
        >
          {item.title}
        </Text>
      </View>

      <View style={styles.sectionContainer}>
        <BodyText>{item.description}</BodyText>
      </View>

      <MiniSubHeader style={{ textAlign: width < 650 ? "center" : "left" }}>
        Tech Stack
      </MiniSubHeader>
      <FeaturesList item={item.features} />
      <View style={styles.sectionContainer}></View>
    </View>
  );
};

export default AppDescription;
