import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width: width < 650 ? scale(80) : scale(52),
      height: width < 650 ? scale(170) : scale(111),
      borderRadius: width < 650 ? scale(8) : scale(4),
      shadowColor: "black",
      shadowOffset: {
        width: scale(2),
        height: scale(0),
      },
      shadowOpacity: scale(0.2),
      shadowRadius: width < 650 ? scale(8) : scale(4),
      elevation: 7,

      marginBottom: scale(6),
      borderWidth: scale(0.5),
      borderColor: COLORS.secondary,
      marginHorizontal: scale(1),
      overflow: "hidden",
    },
    image: {
      width: "100%",
      height: "100%",
    },
  });
