import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      paddingVertical: scale(5),
    },
    innerContainer: {
      flexDirection: "row",
      width: width < 650 ? scale(25) : scale(17.5),
      justifyContent: "space-between",
    },

    icon: {
      width: "100%",
      height: "100%",
    },
    iconContainer: {
      width: width < 650 ? scale(11) : scale(7),
      height: width < 650 ? scale(11) : scale(7),
    },
  });
