import { StyleSheet } from "react-native";
import { COLORS } from "../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: COLORS.secondary,
      flexBasis: "auto",
    },
  });
