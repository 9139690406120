import { View, Text } from "react-native";
import React, { useContext } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import InnerContainer from "../../components/InnerContainer";
import SocialIcons from "../../components/SocialIcons";

const Footer = () => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <InnerContainer>
        <Text style={styles.footerText}>
          Designed & Developed by{" "}
          <Text style={styles.nameStyle}>Chibueze Kalu</Text>
        </Text>
        <Text style={styles.footerText}>
          Built with<Text> ❤️ </Text> React Native Web
        </Text>
      </InnerContainer>
    </View>
  );
};

export default Footer;
