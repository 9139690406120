import { View, Text } from "react-native";
import React, { useContext } from "react";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import AppDemo from "../../components/AppDemo";
import AppDescription from "../../components/AppDescription";
import CustomButton from "../../components/CustomButton";
import { COLORS } from "../../constants/colors";

const ProjectItem = ({ item }) => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  let AppDetail = (
    <>
      <View style={styles.projectInfoMobile}>
        <AppDescription item={item} />
        <AppDemo image={item.imageUrl} />
      </View>
    </>
  );
  if (width > 650) {
    AppDetail = (
      <>
        <View style={styles.projectInfo}>
          <AppDemo image={item.imageUrl} />
          <AppDescription item={item} />
        </View>
      </>
    );
  }

  return (
    <View style={styles.container}>
      {AppDetail}
      <View style={{ alignSelf: "center" }}>
        <CustomButton
          title="Check GitHub"
          widthProp={55}
          href={item.url_link}
          hrefAttrs={{ download: true, rel: "nofollow", target: "blank" }}
        />
      </View>
    </View>
  );
};

export default ProjectItem;
