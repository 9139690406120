import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      marginBottom: width < 650 ? scale(4) : scale(2),
    },
    featureContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    text: {
      fontFamily: "poppins-light",
      fontSize: width < 650 ? scale(7) : scale(4),
      color: COLORS.additional,
      marginBottom: scale(1),
    },
  });
