import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width: width < 650 ? "100%" : "50%",
      backgroundColor: COLORS.primary,
      height:
        width < 650
          ? scale(250)
          : width < 800
          ? scale(140)
          : 1000
          ? scale(125)
          : scale(110),
      padding: width < 650 ? scale(20) : scale(10),
    },
    buttonContainer: {
      alignItems: "center",
    },
  });
