import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width: width < 650 ? "90%" : "55%",
      marginHorizontal: scale(2),
    },
    titleText: {
      fontSize: width < 650 ? scale(8) : scale(5),
      textTransform: "uppercase",
      fontFamily: "poppins-bold",
      color: COLORS.primary,
      textAlign: width < 650 ? "center" : "left",
      marginBottom: scale(2),
    },
  });
