import { createDrawerNavigator } from "@react-navigation/drawer";

import CustomDrawer from "../components/CustomDrawer";

import Main from "../Main";

const Drawer = createDrawerNavigator();

function MyDrawer() {
  return (
    <Drawer.Navigator drawerContent={(props) => <CustomDrawer {...props} />}>
      <Drawer.Screen
        name="Chibueze Kalu"
        component={Main}
        options={{ headerShown: false }}
      />
    </Drawer.Navigator>
  );
}

export default MyDrawer;
