import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      padding: scale(2),
      backgroundColor: COLORS.secondary,
      height: scale(20),
      alignItems: "center",
      justifyContent: "center",
    },
    innerContainer: {
      width:
        width < 500
          ? "90%"
          : width < 800
          ? "80%"
          : width < 1000
          ? "70%"
          : "60%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textStyle: {
      fontSize: scale(10),
      color: "white",
    },
    logo: {
      width: "100%",
      height: "100%",
    },
    logoContainer: {
      width: width < 650 ? scale(20) : scale(12),
      height: width < 650 ? scale(20) : scale(12),
    },

    navTextContainer: {
      flexDirection: "row",
      width: "70%",
      justifyContent: "space-between",
    },
  });
