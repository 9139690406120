import React, { useContext, useState } from "react";
import { Text, View } from "react-native";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";
import BodyText from "../../components/BodyText";
import SectionHeader from "../SectionHeader";

const HeroCaption = ({}) => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);

  return (
    <View style={styles.container}>
      <SectionHeader
        style={styles.heroCaption}
        accessibilityRole="heading"
        accessibilityLevel={1}
      >
        I Build <Text style={styles.scalable}>Scalable</Text> Cross-Platform
        Apps using
        {"\n"}
        React Native
      </SectionHeader>
      <BodyText>
        Hi, I am Chibueze Kalu, a react native developer based in Germany. I
        help businesses design and build scalable cross-platform applications to
        meet the ever-changing trends. I enjoy taking walks in nature and
        playing chess in my free time.
      </BodyText>
    </View>
  );
};

export default HeroCaption;
