import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      marginTop: scale(12),
    },
    projectContainer: {
      width: scale(170),
      flex: 1,
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    icon: {
      fontSize: width < 650 ? scale(20) : scale(10),
      color: COLORS.primary,
    },
  });
