import React, { useContext } from "react";
import { Text, View, Image } from "react-native";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import MiniSubHeader from "../MiniSubHeader";
import MiniBodyText from "../../components/MiniBodyText";

const SkillCard = ({ skillTitle, skillDescription, icon }) => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={icon} />
        </View>
        <View style={styles.skillDescription}>
          <View style={styles.skillTitleContainer}>
            <MiniSubHeader>{skillTitle}</MiniSubHeader>
          </View>
          <View style={styles.skillContentContainer}>
            <MiniBodyText>{skillDescription}</MiniBodyText>
          </View>
        </View>
      </View>
    </View>
  );
};

export default SkillCard;
