import { StyleSheet } from "react-native";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width:
        width < 500
          ? "90%"
          : width < 800
          ? "80%"
          : width < 1000
          ? "70%"
          : "60%",
      alignSelf: "center",
    },
  });
