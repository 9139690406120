import { View, Text } from "react-native";
import React, { useContext } from "react";
import MapView, { Circle, PROVIDER_GOOGLE } from "react-native-maps";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import { COLORS } from "../../constants/colors";

const region = {
  latitude: 51.4958,
  longitude: 6.5321,
  latitudeDelta: 0.04,
  longitudeDelta: 0.04,
};

const MapCard = () => {
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, width, height);
  return (
    <View style={styles.container}>
      <MapView
        style={styles.map}
        initialRegion={region}
        provider={PROVIDER_GOOGLE}
      >
        <Circle
          center={region}
          radius={1000}
          fillColor={COLORS.secondary}
          strokeColor={COLORS.primary}
        />
      </MapView>
    </View>
  );
};

export default MapCard;
