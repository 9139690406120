import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    textStyle: {
      fontFamily: "poppins-light",
      fontSize: width < 650 ? scale(7) : scale(3.25),
      color: COLORS.additional,
    },
    container: {
      marginBottom: scale(1),
    },
  });
