import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      marginTop: scale(12),
      height: width < 650 ? scale(30) : scale(15),
    },
    footerText: {
      color: COLORS.primary,
      fontSize: width < 650 ? scale(6) : scale(3),
      fontFamily: "poppins-light",
      textAlign: "center",
    },
    nameStyle: {
      fontFamily: "poppins-bold",
    },
  });
