import React, { useContext } from "react";
import { View, Animated, useWindowDimensions } from "react-native";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";

const Paginator = ({ data, scrollX }) => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);
  const WIDTH =
    width < 500
      ? width * 0.9
      : width < 800
      ? width * 0.8
      : width < 1000
      ? width * 0.7
      : width * 0.6;

  return (
    <View style={styles.container}>
      {data.map((_, i) => {
        const inputRange = [(i - 1) * WIDTH, i * WIDTH, (i + 1) * WIDTH];
        const dotWidth = scrollX.interpolate({
          inputRange,
          outputRange: [scale(5), scale(10), scale(5)],
          extrapolate: "clamp",
        });
        const opacity = scrollX.interpolate({
          inputRange,
          outputRange: [0.3, 1, 0.3],
          extrapolate: "clamp",
        });
        return (
          <Animated.View
            style={[styles.dot, { width: dotWidth, opacity }]}
            key={i.toString()}
          />
        );
      })}
    </View>
  );
};

export default Paginator;
