import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width:
        width < 500
          ? width * 0.9
          : width < 800
          ? width * 0.8
          : width < 1000
          ? width * 0.7
          : width * 0.6,
      marginRight: scale(10),
      marginVertical: scale(5),
    },
    projectInfo: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    projectInfoMobile: {
      alignItems: "center",
    },
  });
