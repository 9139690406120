import axios from "axios";
import React, {
  createContext,
  useReducer,
  useCallback,
  useLayoutEffect,
} from "react";
import { useWindowDimensions } from "react-native";

const formID = "r38xbdDS";
const formSparkUrl = `https://submit-form.com/${formID}`;

//reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_DIMENSIONS":
      return { ...state, ...action.payload };
    case "UPDATE_NAVIDATA":
      return { ...state, naviData: action.payload };
    case "UPDATE_NAVIFUNCTION":
      return { ...state, naviFunction: action.payload };
    case "UPDATE_MAIL_NAME":
      return { ...state, mail: { ...state.mail, name: action.payload } };
    case "UPDATE_MAIL_EMAIL":
      return { ...state, mail: { ...state.mail, email: action.payload } };
    case "UPDATE_MAIL_SUBJECT":
      return { ...state, mail: { ...state.mail, subject: action.payload } };
    case "UPDATE_MAIL_MESSAGE":
      return { ...state, mail: { ...state.mail, message: action.payload } };
    case "SUBMITED":
      return { ...state, submitMail: action.payload };
    case "NOT_SUBMITED":
      return { ...state, submitMail: action.payload };
    case "UPDATE_REACPTCHA_TOKEN":
      return { ...state, recaptchaToken: action.payload };
    case "CLEAR_FORM":
      return {
        ...state,
        mail: {
          name: "",
          email: "",
          subject: "",
          message: "",
        },
      };
    default:
      return state;
  }
};
//state

//context
export const Context = createContext();

//provider
export const Provider = ({ children, navigation }) => {
  const { width, height } = useWindowDimensions();
  const [state, dispatch] = useReducer(reducer, {
    width,
    height,
    naviData: {},
    naviFunction: () => {},
    mail: { name: "", email: "", subject: "", message: "" },
    submitMail: { submited: false, resMessage: "", notSubmited: false },
    recaptchaToken: null,
  });

  const BoundActions = {};

  useLayoutEffect(() => {
    updateDimensions();
    updateNaviData();
    updateNaviFunction();
  }, [width, height]);

  //actions
  const updateDimensions = useCallback(() => {
    dispatch({ type: "UPDATE_DIMENSIONS", payload: { width, height } });
  }, [width, height]);

  const scale = useCallback(
    (size) => {
      const guidelineBaseWidth = 350;
      const guidelineBaseHeight = 680;
      const avgWidth = (width / guidelineBaseWidth) * size;
      const avgHeight = (height / guidelineBaseHeight) * size;

      const value = avgWidth + avgHeight;

      return value;
    },
    [width, height]
  );

  const updateNaviData = useCallback(
    (data) => {
      dispatch({ type: "UPDATE_NAVIDATA", payload: data });
    },
    [width, height]
  );
  const updateNaviFunction = useCallback(
    (func) => {
      dispatch({ type: "UPDATE_NAVIFUNCTION", payload: func });
    },
    [width, height]
  );

  const updateMailName = (name) => {
    dispatch({
      type: "UPDATE_MAIL_NAME",
      payload: name,
    });
  };

  const updateMailEmail = (email) => {
    dispatch({
      type: "UPDATE_MAIL_EMAIL",
      payload: email,
    });
  };
  const updateMailSubject = (subject) => {
    dispatch({
      type: "UPDATE_MAIL_SUBJECT",
      payload: subject,
    });
  };
  const updateMailMessage = (message) => {
    dispatch({
      type: "UPDATE_MAIL_MESSAGE",
      payload: message,
    });
  };

  const onFormSubmit = async () => {
    await postSubmission();
    clearForm();
  };

  const postSubmission = async () => {
    try {
      const response = await axios.post(formSparkUrl, {
        ...state.mail,
        "g-recaptcha-response": state.recaptchaToken,
      });
      dispatch({
        type: "SUBMITED",
        payload: {
          submited: true,
          resMessage: "Email Sent",
          notSubmited: false,
        },
      });
    } catch (e) {
      dispatch({
        type: "NOT_SUBMITED",
        payload: {
          notSubmited: true,
          resMessage: "Error Sending Email",
          submited: false,
        },
      });
    }
  };

  const clearForm = () => {
    dispatch({ type: "CLEAR_FORM" });
  };

  const updateRecaptchaToken = (token) => {
    dispatch({ type: "UPDATE_REACPTCHA_TOKEN", payload: token });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        updateDimensions,
        updateNaviFunction,
        updateNaviData,
        scale,
        updateMailName,
        updateMailEmail,
        updateMailSubject,
        updateMailMessage,
        onFormSubmit,
        updateRecaptchaToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};
