import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, WIDTH, HEIGHT, RADIUS, width) =>
  StyleSheet.create({
    container: {
      width: WIDTH,
      height: HEIGHT,
      backgroundColor: "white",
      borderColor: COLORS.primary,
      borderWidth: width < 650 ? scale(2) : scale(1),
      borderRadius: RADIUS,
      overflow: "hidden",
      paddingTop: scale(10),
      // marginTop: scale(10),
    },
    image: {
      width: "100%",
      height: "100%",
    },
  });
