import React, { useContext, useState, useLayoutEffect } from "react";
import { View, useWindowDimensions, Image } from "react-native";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";

const HeroImage = () => {
  const { scale, width, height } = useContext(Context);

  let WIDTH = scale(45);
  let HEIGHT = scale(65);
  let RADIUS = scale(25);

  if (width < 650) {
    WIDTH = scale(65);
    HEIGHT = scale(90);
    RADIUS = scale(55);
  }

  const styles = myStyles(scale, WIDTH, HEIGHT, RADIUS, width);

  return (
    <View style={styles.container}>
      <Image
        source={require("../../../assets/images/Profile Picture.png")}
        style={styles.image}
        resizeMode="contain"
      />
    </View>
  );
};

export default HeroImage;
