import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      justifyContent: "center",
      paddingLeft: width > 650 ? 0 : scale(10),
      backgroundColor: COLORS.primary,
      height: "100%",
    },
    menuContainer: {
      height: "30%",
      justifyContent: "space-between",
    },
    closeIcon: {
      position: "absolute",
      top: scale(5),
      right: scale(5),
      color: COLORS.additional,
      fontSize: scale(12),
    },
  });
