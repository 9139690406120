import React, { useContext, useState, useCallback } from "react";
import { Text, Pressable } from "react-native";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";

const NavBarText = ({
  children,
  hoverInColor,
  hoverOutColor,
  fontSize,
  onPress,
}) => {
  const [onHover, setOnHover] = useState(false);
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(
    scale,
    width,
    height,
    hoverInColor,
    hoverOutColor,
    fontSize
  );

  const handlerHoverIn = useCallback(() => {
    setOnHover(true);
  }, []);
  const handleHoverOut = useCallback(() => {
    setOnHover(false);
  }, []);
  return (
    <Pressable
      onHoverIn={handlerHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlerHoverIn}
      onPressOut={handleHoverOut}
      onPress={onPress}
    >
      <Text
        style={[
          onHover ? styles.onHoverInText : styles.onHoverOutText,
          styles.textStyle,
        ]}
      >
        {children}
      </Text>
    </Pressable>
  );
};

export default NavBarText;
