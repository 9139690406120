import React, { useContext, useState } from "react";
import { Text, View, TouchableWithoutFeedback } from "react-native";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";
import HeroCaption from "../../components/HeroCaption";
import HeroImage from "../../components/HeroImage";
import SocialIcons from "../../components/SocialIcons";
import CustomButton from "../../components/CustomButton";
import InnerContainer from "../../components/InnerContainer";
import { COLORS } from "../../constants/colors";
import PDF from "../../../assets/Chibueze_Kalu_CV-compressed.pdf";

const HeroSection = ({}) => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);

  let HeroContent = (
    <>
      <View style={{ alignItems: "center" }}>
        <HeroImage />
        <SocialIcons />
        <HeroCaption />

        <CustomButton
          title="Download Resume"
          colorIn={COLORS.additional}
          colorOut={COLORS.primary}
          widthProp={80}
          href={PDF}
        />
      </View>
    </>
  );
  if (width > 650) {
    HeroContent = (
      <>
        <View style={styles.hero}>
          <HeroCaption />
          <View>
            <HeroImage />
            <SocialIcons />
          </View>
        </View>

        <CustomButton
          title="Download Resume"
          colorIn={COLORS.additional}
          colorOut={COLORS.primary}
          widthProp={60}
          href={PDF}
        />
      </>
    );
  }
  return (
    <View style={styles.container}>
      <InnerContainer>{HeroContent}</InnerContainer>
    </View>
  );
};

export default HeroSection;
