import { useState, useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { enableScreens } from "react-native-screens";

enableScreens();

import { fetchFonts } from "./src/constants/fonts";
import AppLoading from "expo-app-loading";

import { Provider } from "./src/createDataContext";
import MyDrawer from "./src/navigator";

export default function App() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState("");

  const finishLoadingHandler = useCallback(() => {
    setDataLoaded(true);
  }, [dataLoaded]);
  const errorLoadingHandler = useCallback(
    (err) => {
      setError(err);
    },
    [dataLoaded]
  );

  if (!dataLoaded) {
    return (
      <AppLoading
        startAsync={fetchFonts}
        onFinish={finishLoadingHandler}
        onError={errorLoadingHandler}
      />
    );
  }
  return (
    <NavigationContainer>
      <Provider>
        <MyDrawer />
      </Provider>
    </NavigationContainer>
  );
}
