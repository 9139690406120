import { StyleSheet } from "react-native";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      marginTop: scale(12),
    },
    hero: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
