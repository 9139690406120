import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      borderBottomWidth: width < 650 ? scale(0.75) : scale(0.4),
      borderColor: COLORS.additional,
      marginVertical: width < 650 ? scale(5) : scale(2.5),
      paddingBottom: width < 650 ? scale(3) : scale(1.5),
    },

    textInput: {
      fontFamily: "poppins-light",
      fontSize: width < 650 ? scale(7.5) : scale(3.5),
      color: COLORS.additional,
    },
    icon: {
      fontSize: width < 650 ? scale(10) : scale(5),
      paddingRight: width < 650 ? scale(4) : scale(2),

      color: COLORS.additional,
    },
    textinputContainer: {
      flex: 1,
    },
  });
