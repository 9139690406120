import { StyleSheet } from "react-native";

import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      width: "100%",
      marginBottom: scale(4),
    },
    innerContainer: {
      flexDirection: "row",
    },
    imageContainer: {
      width: width < 650 ? scale(45) : scale(25),
      height: width < 650 ? scale(45) : scale(25),
      borderColor: COLORS.primary,
      borderWidth: width < 650 ? scale(2) : scale(1),
      padding: scale(4),
    },
    image: {
      width: "100%",
      height: "100%",
    },
    skillTitle: {
      color: COLORS.primary,
      fontFamily: "poppins-regular",
      letterSpacing: scale(0.5),
      fontSize: width < 650 ? scale(8) : scale(5),
      textTransform: "uppercase",
    },
    skillDescription: {
      marginLeft: width < 650 ? scale(4) : scale(3),
      width: width < 650 ? "70%" : "60%",
    },
    skillContent: {
      fontFamily: "poppins-light",
      fontSize: width < 650 ? scale(6.5) : scale(3.5),
      color: COLORS.additional,
    },
    skillContentContainer: {
      marginTop: scale(1),
    },
  });
