import React, { useState, useContext, useCallback } from "react";
import { View, Text, Pressable } from "react-native";

import { myStyles } from "./myStyles";
import { COLORS } from "../../constants/colors";
import { Context } from "../../createDataContext";

const CustomButton = ({
  title,
  widthProp = 50,
  colorIn = COLORS.additional,
  colorOut = COLORS.primary,
  fontSize = 5,
  href,
  onPress = null,
}) => {
  const [onHover, setOnHover] = useState(false);
  const { scale, width, height } = useContext(Context);

  const styles = myStyles(scale, widthProp, colorIn, colorOut, fontSize, width);

  const handlerHoverIn = useCallback(() => {
    setOnHover(true);
  }, []);
  const handleHoverOut = useCallback(() => {
    setOnHover(false);
  }, []);
  return (
    <Pressable
      onHoverIn={handlerHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlerHoverIn}
      onPressOut={handleHoverOut}
      style={styles.container}
      href={href}
      hrefAttrs={{ download: true, rel: "nofollow", target: "blank" }}
      onPress={onPress}
    >
      <View
        style={[
          styles.buttonContainer,
          onHover ? styles.onHoverInContainer : styles.onHoverOutContainer,
        ]}
      >
        <Text
          style={[
            onHover ? styles.onHoverInText : styles.onHoverOutText,
            styles.textStyle,
          ]}
        >
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

export default CustomButton;
