import React, { useContext } from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";

const SocialIcons = () => {
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <TouchableOpacity
          href="https://www.linkedin.com/in/chibueze-kalu/"
          hrefAttrs={{ download: true, rel: "nofollow", target: "blank" }}
        >
          <View style={styles.iconContainer}>
            <Image
              source={require("../../../assets/images/LinkedinGold.png")}
              style={styles.icon}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          href="https://github.com/devkalu"
          hrefAttrs={{ download: true, rel: "nofollow", target: "blank" }}
        >
          <View style={styles.iconContainer}>
            <Image
              source={require("../../../assets/images/githubGold.png")}
              style={styles.icon}
            />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SocialIcons;
