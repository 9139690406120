import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {
      justifyContent: "center",
      width: width < 650 ? "100%" : "65%",
      marginBottom: scale(5),
    },
    heroCaption: {
      fontFamily: "poppins-bold",
      fontSize: width < 650 ? scale(18) : scale(12),
      color: COLORS.primary,
      letterSpacing: scale(0.02),
      lineHeight: width < 650 ? scale(19) : scale(14),
      marginBottom: scale(5),
      textAlign: width < 650 ? "center" : "left",
    },
    scalable: {
      fontFamily: "Poppins-LightItalic",
    },
  });
