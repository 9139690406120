import { StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";

export const myStyles = (scale, width, height) =>
  StyleSheet.create({
    container: {},
    contactContainer: {
      flexDirection: "row",
    },
    email: {
      fontFamily: "poppins-bold",
    },
  });
