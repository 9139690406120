import React, { useContext, useState, useRef, useCallback } from "react";
import {
  View,
  Image,
  FlatList,
  TouchableOpacity,
  Animated,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";

import { Context } from "../../createDataContext";
import { myStyles } from "./myStyles";
import InnerContainer from "../../components/InnerContainer";
import SectionHeader from "../../components/SectionHeader";
import ProjectItem from "../../components/ProjectItem";
import Paginator from "../../components/Paginator";

const PROJECTS = [
  {
    id: "1",
    imageUrl: "https://i.imgur.com/QqjJEyo.gif",
    title: "Restaurant Search App",
    description:
      "Fetching data from a remote API and displaying it in a user-friendly manner is one of the common operations in mobile app development. In this two-screen app, I used Axios - a javascript library for making HTTP requests to fetch a list of restaurants from Yelp API using input data from the TextInput component alongside predefined parameters.",
    features: ["Expo", "React Navigation 4", "Axios", "Yelp API"],
    url_link: "https://github.com/devkalu/RestaurantSearch",
  },
  {
    id: "2",
    imageUrl: "https://i.imgur.com/9mmOC91.gif",
    title: "Tinder Swipe Clone",
    description:
      "The thin line between a good app and a great app can be a perfectly timed animation with a responsive gesture handler. The dating app 'Tinder' has a good demonstration of this feature in its swipe to select or deselect functionality. I tried to replicate this feature in this single screen app.",
    features: ["Animated API", "Expo", "PanResponder"],
    url_link: "https://github.com/devkalu/tinder-clone",
  },
  {
    id: "3",
    imageUrl: "https://i.imgur.com/EzgKJK3.gif",
    title: "To-Do App",
    description:
      "CRUD an acronym that refers to the four functions that are considered necessary to implement a persistent storage application: create, read, update and delete. Json-server along with ngrok together helped me create a backend that can be accessed over the internet. This app though simple helped me understand the crucial operations of creating, reading, updating and deleting data.",
    features: [
      "Expo",
      "Context API",
      "Ngrok",
      "Json-Server",
      "Stack Navigator",
    ],
    url_link: "https://github.com/devkalu/todo",
  },
];

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { scale, width, height } = useContext(Context);
  const styles = myStyles(scale, width, height);
  const scrollX = useRef(new Animated.Value(0)).current;

  const WIDTH =
    width < 500
      ? width * 0.9
      : width < 800
      ? width * 0.8
      : width < 1000
      ? width * 0.7
      : width * 0.6;

  const slidesRef = useRef(null);

  const viewableItemsChanged = useRef(({ viewableItems, changed }) => {
    if (viewableItems.length) {
      const { index } = viewableItems[0];
      setCurrentIndex(index);
    }
  }).current;

  const viewConfig = useRef({ viewAreaCoveragePercentThreshold: 50 }).current;

  const onPressRight = useCallback(() => {
    if (currentIndex < PROJECTS.length - 1) {
      const index = currentIndex + 1;
      slidesRef.current.scrollToIndex({ index: index, animated: true });
    }
    return;
  }, [currentIndex]);

  const onPressLeft = useCallback(() => {
    if (currentIndex > 0) {
      const index = currentIndex - 1;
      slidesRef.current.scrollToIndex({ index: index, animated: true });
    }
    return;
  }, [currentIndex]);

  const getItemLayout = (data, index) => {
    return { length: WIDTH, offset: WIDTH * index, index };
  };

  return (
    <View style={styles.container}>
      <InnerContainer>
        <SectionHeader accessibilityRole="heading" accessibilityLevel={2}>
          Projects
        </SectionHeader>
        <View style={{ flex: 1 }}>
          <FlatList
            data={PROJECTS}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => <ProjectItem item={item} />}
            horizontal
            showsHorizontalScrollIndicator={false}
            pagingEnabled
            bounce={false}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              { useNativeDriver: false }
            )}
            scrollEventThrottle={32}
            onViewableItemsChanged={viewableItemsChanged}
            viewabilityConfig={viewConfig}
            ref={slidesRef}
            initialScrollIndex={currentIndex}
            getItemLayout={getItemLayout}
            extraData={currentIndex}
          />
        </View>

        <View style={styles.row}>
          <TouchableOpacity onPress={onPressLeft}>
            <AntDesign name="leftcircleo" style={styles.icon} />
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressRight}>
            <AntDesign name="rightcircleo" style={styles.icon} />
          </TouchableOpacity>
        </View>
        <Paginator data={PROJECTS} scrollX={scrollX} />
      </InnerContainer>
    </View>
  );
};

export default Projects;
