import { View, Text } from "react-native";
import React, { useContext, useState, useCallback, useRef } from "react";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import ReCAPTCHA from "react-google-recaptcha";

import { myStyles } from "./myStyles";
import { Context } from "../../createDataContext";
import { COLORS } from "../../constants/colors";
import Input from "../Input";
import CustomButton from "../CustomButton";

const recaptchaKey = "6LcG5ggfAAAAAFXKlEvvn5AkF0JkArSk_ERdXgqt";

const ContactForm = () => {
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    scale,
    width,
    height,
    updateMailName,
    updateMailEmail,
    updateMailSubject,
    updateMailMessage,
    mail: { name, email, subject, message },
    onFormSubmit,
    submitMail,
    updateRecaptchaToken,
    recaptchaToken,
  } = useContext(Context);

  const recaptchaRef = useRef(null);

  const validateEmail = useCallback(
    (text) => {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(text) === false) {
        //Incorrect
        setIsInvalid(true);
      } else {
        //Correct
        setIsInvalid(false);
      }
    },
    [email]
  );

  const styles = myStyles(scale, width, height);

  return (
    <View style={styles.container}>
      <Input
        Icon={Ionicons}
        IconName="person"
        placeholderText="Enter your name"
        multi={false}
        value={name}
        onChangeValue={(text) => updateMailName(text)}
      />

      <Input
        Icon={Ionicons}
        IconName="mail"
        placeholderText="Enter your email"
        multi={false}
        value={email}
        onChangeValue={updateMailEmail}
        onEndEditing={() => {
          validateEmail(email);
        }}
      />

      <Input
        Icon={MaterialCommunityIcons}
        IconName="mail"
        placeholderText="Enter a subject"
        multi={false}
        value={subject}
        onChangeValue={updateMailSubject}
      />
      <Input
        Icon={MaterialCommunityIcons}
        IconName="message-draw"
        placeholderText="Enter a message"
        multi={true}
        lines={5}
        value={message}
        onChangeValue={updateMailMessage}
      />
      <View style={styles.buttonContainer}>
        {isInvalid ? (
          <Text
            style={{
              fontSize: width < 650 ? scale(6.5) : scale(3.5),
              color: "red",
              marginVertical: scale(0.5),
            }}
          >
            Please enter a valid email
          </Text>
        ) : null}

        {/* submited: true,
          resMessage: "Email Sent",
          notSubmited: false, */}
        {submitMail.notSubmited ? (
          <Text
            style={{
              fontSize: width < 650 ? scale(6.5) : scale(3.5),
              color: "red",
              marginVertical: scale(0.5),
            }}
          >
            {submitMail.resMessage}
          </Text>
        ) : null}
        {submitMail.submited ? (
          <Text
            style={{
              fontSize: width < 650 ? scale(6.5) : scale(3.5),
              color: "green",
              marginVertical: scale(0.5),
            }}
          >
            {submitMail.resMessage}
          </Text>
        ) : null}
        <View style={{ transform: scale(scale(0.1)) }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={updateRecaptchaToken}
          />
        </View>

        <CustomButton
          title="Send"
          widthProp={20}
          fontSize={5}
          colorIn={COLORS.secondary}
          colorOut={COLORS.additional}
          disabled={true}
          onPress={() => {
            onFormSubmit();
            recaptchaRef.current.reset();
          }}
        />
      </View>
    </View>
  );
};

export default ContactForm;
